.login__cont_new {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff6f6;
}
.login_new {
  display: flex;
  align-items: flex-start;

  margin: 0 0 0 0;
  width: 100%;
  min-height: 350px;
  background: #fff6f6;
}
.login .sec__left_new {
  /* flex: 0.4; */
  margin-left: 126px;
  width: 100%;
  min-height: 600px;
  height: 100%;
  position: relative;
}

.login .sec__left_new .upper {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 58px;
}
.login .sec__left_new .uppersec {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 58px;
  margin-left: 20px;
}
.login .sec__left_new .images {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
}
.login .sec__left_new .images img {
  margin: 10px 10px 10px 10px;
  height: 150px;
  width: 150px;
}
.login .sec__left_new .heading {
  font-family: "lexend";
  font-weight: 1000;
  font-size: 40px;
  color: #0b1c39;
}
.login .sec__left_new .content {
  display: flex;
  flex-direction: column;
  line-height: 48px;
  text-align: left;
}
.login .sec__left_new .lower {
  font-size: 22px;
  font-family: "lexend";
  font-weight: 600;
}
.login .sec__left_new .bottom {
  display: flex;
  flex-direction: row;
  row-gap: 30px;

  margin-top: 40px;
}
.login .sec__left_new .bottom_1 {
  display: flex;
  flex-direction: column;
  margin-right: 90px;
  text-align: left;
}
.login .sec__right_new {
  /* flex: 0.6; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  justify-content: center;
  margin: auto;
  row-gap: 1rem;
  margin: 3rem 10% 1rem 7%;
  color: #6e6e6e;
  background-color: white;
  padding: 30px 20px 30px 30px;
  background-color: white;
  align-items: center;
  width: 80%;
  border: 2px solid #e9e9e9;
}
.login .sec__right_new .login__form {
  width: 100%;
}
.login .sec__right_new .login__form .account_status {
  text-align: center !important;
}
.login .sec__right_new .login__form .circles {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 40%;
}
.login .sec__right_new .login__form .circles img {
  margin-right: 20px;
}

.login .sec__right_new .logreg__title {
  margin-bottom: 1rem;
}
.login .sec__right_new .login__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.6rem;
  width: 100%;
  max-width: 480px;
  /* background: peru; */
}
.login .sec__right_new .login__form label {
  font-size: 0.85rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.login .sec__right_new .login__form .epass {
  color: #0b1c39;
  background: #fafafa;
  height: 2.7rem;
  border-radius: 1px;
  border: 1.5px solid rgb(230, 230, 230);
  font-family: "Poppins";
  outline: none;
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
  width: 100%;
  box-sizing: border-box;
}
.login .sec__right_new .login__form .epass::placeholder {
  color: rgb(190, 190, 190);
  opacity: 1; /* Firefox */
}
.login .sec__right .login__form .epass::-webkit-outer-spin-button,
.login .sec__right .login__form .epass::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.login .sec__right_new .login__form .log__for {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.5rem;
  width: 100%;
}
.login .sec__right_new .login__form .log__for label {
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  column-gap: 0.5rem;
  cursor: pointer;
  text-align: left;
}
.login .sec__right_new .login__form .login__btn {
  font-size: 1rem;
  margin-top: 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 1px;
  color: rgba(255, 255, 255, 0.89);
  background: #191e22;
  height: 3rem;
  width: 100%;
  font-family: "Poppins";
  justify-content: center;
  display: flex;
  align-items: center;
}
#forgotpassword {
  border-top: 2px solid #0000001a;
  margin-top: 30px;
}
.login__form .password_label {
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: space-evenly;
}
.login__form .password_label .min__char {
  font-size: 8px;
}
@media screen and (max-width: 1024px) {
  .login {
    margin-right: 0;
  }
  .login #left-section {
    display: none;
  }
}
@media screen and (max-width: 880px) {
  .login {
    flex-direction: column;

    align-items: center;
    margin: 0 5%;
  }
  .login #left-section {
    display: none;
  }
  .login .sec__right .logreg__title {
    font-size: 1.6rem;
  }
  .login .sec__right {
    margin: 3rem 5%;
    align-items: center;
  }
}
